import {auth, eachElement} from './firebase.js';

auth.onAuthStateChanged(user => {
  if (user) {
    eachElement(".auth-invisible", (e) => e.classList.add("hidden"));
    eachElement(".auth-visible", (e) => e.classList.remove("hidden"));
    document.getElementById("loader").style.display="none";
  } else {
    eachElement(".auth-invisible", (e) => e.classList.remove("hidden"));
    eachElement(".auth-visible", (e) => e.classList.add("hidden"));
    document.getElementById("loader").style.display="none";
  }
});
